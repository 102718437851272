@import url(http://fonts.cdnfonts.com/css/cunia);

.nav-pills .nav-link {
    padding: 1rem 2rem;
    border: 1px solid #7070701c;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    color: #000;
    font-weight: 600;
}
#v-pills-tab {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.00); 
    /* border-right: 2px solid #C70C0C; */
}

.nav-tab .nav-link {
    padding: .1rem  !important;
    border: 1px solid #70707000 !important;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    color: #000;
    margin: 0rem .7rem;
    font-weight: 700;
    font-size: 14px !important;
    
}
.nav-tab .nav-link.active {
    color: #C70C0C !important;
    background-color: transparent !important;
    border-bottom: 2px solid #C70C0C !important;
}

.img-tab img {
    width: 180px;
    height: 160px;
    object-fit: cover;
}
.check-result{
    font-size: 14px;
    font-family: poppins;
    font-weight: 600;
}
.img-wrappers {
 
    padding: .8rem;
    box-sizing: border-box;
    text-align: -webkit-center;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    cursor: pointer;
}

.menu {
    background: #ffffff;
    border-radius: 2px;
    border-top: 2px solid #C70C0C;
    position: absolute;
    top: 0;
    right: 0;
    width: 900px !important;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    z-index: 1;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
}

.menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
/* Accordion */

.accordion{
  border-top: 2px solid #d3d3d369;
     
}
.accordion-header{
    padding: 1rem;

  }
  .accordion-header:hover{
    background: #e9ecef8a;
    cursor: pointer;
  }

.accordion-body p{
   
    padding: 1rem;
}  

/* Modal */
.modal-box{
position: fixed;
display: flex;
justify-content: center;
align-items : center;
width:100%;
height:100%;
top:0;
left:0;
right:0;
bottom: 0;
background-color: rgba(34, 34, 34, 0.7);


z-index: 9999;


}
.modal-wrapper{
  width:800px;
  height: 500px;
  box-shadow: 0 5px 16px rgba(0,0,0,0.2);
  background:#fff;
  color:#000;
  display:grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index:10;
  border-radius:5px;
}
.closeModal{
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fff;
  border-radius: 50%;
  padding: 3px 7px;
  box-shadow: 0px 0px 3px 3px rgb(140 140 140 / 20%);
  cursor: pointer;
  width: 30px;
  height: 30px
}

.img-wrapper{
  padding: .8rem;
  box-sizing: border-box;
  text-align: -webkit-center;
  border: 4px solid transparent;
}
.img-wrapper:hover{
  
  border: 4px solid #BA0B0B;
  padding: .8rem;
  box-sizing: border-box;
  text-align: -webkit-center;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  cursor: pointer;
}
.img-box img{
  width: 230px;
  height:210px;
  object-fit: cover;

}
.modal-button{
  width: 100%;
  padding: 15px 5px;
  background: #BA0B0B;
  color:#fff !important;
  font-family: cunia;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.modalpop-title{
 padding:2rem 0;
 border-top: 2px solid rgba(0,0,0,.2);
}
.modalpop-title h5 {
  font-family: 'Poppins';
  color: #000;
  font-weight: 700;
}

/* The radio */
.radio {
 
  display: block;
 position: relative;
 padding-left: 30px;
 margin-bottom: 12px;
 cursor: pointer;
 font-size: 20px;
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none
}

/* Hide the browser's default radio button */
.radio input {
 position: absolute;
 opacity: 0;
 cursor: pointer;
}

/* Create a custom radio button */
.checkround {

 position: absolute;
 top: 6px;
 left: 0;
 height: 20px;
 width: 20px;
 background-color: #fff ;
 border-color:#BA0B0B;
 border-style:solid;
 border-width:2px;
  border-radius: 50%;
 
}


/* When the radio button is checked, add a blue background */
.radio input:checked ~ .checkround {
 background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkround:after {
 content: "";
 position: absolute;
 display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked ~ .checkround:after {
 display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkround:after {
  left: 2px;
 top: 2px;
 width: 12px;
 height: 12px;
 border-radius: 50%;
 background:#BA0B0B;
 

}

/* The check */
.check {
 display: block;
 position: relative;
 padding-left: 25px;
 margin-bottom: 12px;
 padding-right: 15px;
 cursor: pointer;
 font-size: 18px;
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;
}

/* Hide the browser's default checkbox */
.check input {
 position: absolute;
 opacity: 0;
 cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
 position: absolute;
 top: 3px;
 left: 0;
 height: 18px;
 width: 18px;
 background-color: #fff ;
 border-color:#BA0B0B;
 border-style:solid;
 border-width:2px;
}



/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark {
 background-color: #fff  ;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
 content: "";
 position: absolute;
 display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
 display: block;
}

/* Style the checkmark/indicator */
.check .checkmark:after {
 left: 5px;
 top: 1px;
 width: 5px;
 height: 10px;
 border: solid ;
 border-color:#BA0B0B;
 border-width: 0 3px 3px 0;
 transform: rotate(45deg);
}

/* .cust-btn{
margin-bottom: 10px;
background-color: #f8204f;
border-width: 2px;
border-color: #f8204f;
color: #fff;
}
.cust-btn:hover{

border-color: #f8204f;
background-color: #fff;
color: #f8204f;
border-radius: 20px;
transform-style: 2s;

} */

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #BA0B0B; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(150, 7, 7); 
}

.countdown-wrapperx {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0 -8px 0 -8px;
}
.countdown-wrappers {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0 -8px 0 -8px;
}

.countdown-wrappers .time-section {
  padding: 0px 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #f9f5f5 !important;
}
.countDisplay{
  width: 50px;
  border: 1px solid #d3d3d3;
  margin: 0px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: poppins;
  height: 38px;

}
.btn-danger {
  color: #fff;
  background-color: #ba0b0b !important;
  border-color: #ba0b0b;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}
.cart-box{
  border-bottom: 2px solid #d3d3d3b5;
  padding-bottom: 1rem;
}
.cart-box1{
  padding-top: 1rem;
}


.multi-draws {
  padding: 30px 0;
  overflow: hidden;
  background: #ba0b0b17;
}
.text-danger{
  color:#ba0b0b  !important;
}


.select-form{
  background: transparent;
    
    border: 1px solid #716f6f;
 
  
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 15px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  
  border-radius: 2px;
 
  padding: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-weight: 600;
}

.select-form:focus{
  background: transparent;
    padding: 1rem;
    border: 1px solid #716f6f;
    outline-width: 0;
}



.border-top {
  border-top: 2px solid #dee2e6!important;
}






.checkout-wrapper{
margin-top: 2rem;
}
.checkout-btn{
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1.3rem;
    padding: 1rem;
    color: #fff !important;
    margin-bottom: 1.5rem;
    border-radius: 0;
}

.checkout-btn-danger{
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1.3rem;
    padding: 1rem;
    color: #fff !important;
    background: rgb(199, 12, 12) ;
    border-radius: 0;
}
.checkout-card{
        box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.09);
        border-radius: .4rem;
}
.checkout-card-header{
    background: #d3d3d3;
    padding: 1.25rem .5rem;
   
}
.checkout-header h6{
    position: absolute;
    left: 8rem;
    margin-top: -7px;
}
.checkout-header img{
    position: absolute;
    top: -28px;
    
    left: 1px;
}
.border-bottom{
   border-bottom: 2px solid #d3d3d39c !important;
   padding-bottom: .5rem;
}
.view-more{
    padding-top: .2rem;
}
.view-more:hover{
    cursor: pointer;
   
}
.list-inline{
    margin-bottom: 0px !important;
}
/* .list-inline-item {
  
    width: 30px;
    height: 30px;
    background:#49A35A;
    text-align: center;
    align-items: center;
    border-radius: 50%;
    padding: auto;
  
    color: #fff;
    font-weight: 600;
    padding-top: 4px;
} */
.list-inline-item {
    width: 25px;
    height: 25px;
    background: #49A35A;
    text-align: center;
    align-items: center;
    border-radius: 50%;
    padding: auto;
    color: #fff;
    font-weight: 600;
    padding-top: 4px !important;
    font-size: 12px;
}
.list-inline-item:last-child{
 background: rgb(199, 12, 12) ;
}
.table-checkout {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border: 2px solid #d3d3d361 !important;
}
.table-checkout thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    color: #d3d3d3;
}

.result .table{
    background: #d2e8d661;
}

.result-thread{
    border-bottom: 1px solid #49A35A !important;
}

.make-me-sticky{
    position: -webkit-sticky;
      position: sticky;
      top: 15%;
      
      
}
*{
 font-family:poppins;
/* 
 Century Gothic,Arial,sans-serif */
}
.dashboard-wrapper{
    padding-top: 2rem;
}
#v-pills-tab{
box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.09);
/* border-right: 2px solid #C70C0C; */

}

.nav-pills .nav-link{
  padding: 1rem;
  border: 1px solid #7070701c;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.nav-pills .nav-link.active{
    color: #fff !important;
    background-color: #C70C0C !important;

}
.dashboard a{
    color: #222;
    font-size: 16px;
    font-weight: 600;
}
.dashboard-card {
    padding: 2rem 0rem 2rem 2rem;
    box-shadow:0px 7px 20px rgba(0, 0, 0, 0.09);
    border-radius: .4rem;
    
}

.dashboard-label label{
    font-size: 12px
}
.dashboard-header{
    padding-left: 5rem;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: 1.2rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
  
    background-clip: padding-box;
    border: 1px solid #f3f3f3;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ecedef;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgb(212 213 214 / 25%);
}

.form-group{
    padding:3px;
    border:2px solid #d3d3d37a;
    margin:10px;
    margin-left: 0px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
  .form-group>label{
    position:absolute;
    top:-1px;
    left:40px;
    padding: 2px 6px;

    background-color:white;
  }
  
  .form-group>input{
    border:none;
  }
  .dashboard-card a{
   color: #28a745!important;
  }
  .side-tab a{
    
        color: #222;
        font-size: 14px;
        /* font-weight: 600; */

  }

  @media (max-width: 540px) {
   .dashboard-header{
     padding-left: 1rem;
   }
  }







.jackpot {
    width: 100%;
    height: 70vh;
    background: url(/images/herobg.svg);
    background-repeat: repeat;
    
}
.jackpot-main{
margin-top:-29rem
}

.jackpot-header h1{
    font-size: 5.7rem;
}
.jackpot-header small{
    font-weight: 600;
}
.nav-tabs .nav-item {
    margin: 2px;
}
.nav-tabs .nav-link.active {
    /* color: #AD1519 !important; */
    background-color: #fff;
    border-color: #fff;
    font-size: 14px;
    font-weight: 700;
}
.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-radius: 0rem;

    /* color: #fff !important; */
    background: transparent;
    border: 2px solid #fff;
    -webkit-border-radius: 0rem;
    -moz-border-radius: 0rem;
    -ms-border-radius: 0rem;
    -o-border-radius: 0rem;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins';
}
.nav-tabs .nav-link:hover{
    cursor: pointer;
}
.nav-tabs {
    border-bottom: 1px solid #dee2e600;
}
.is-active{
    background:#fff !important; color:#AD1519 !important;
    border-radius:0rem ;
    -webkit-border-radius:0rem ;
    -moz-border-radius:0rem ;
    -ms-border-radius:0rem ;
    -o-border-radius:0rem ;
    font-size: 14px;
    font-weight: 600;
    border: 2px solid #fff;
    font-family: 'Poppins';
    padding: .5rem 1rem;
}
.not-active{
    background:transparent !important;
    color:#fff !important;
    border: 2px solid #fff;
    border-radius:0rem ;
    -webkit-border-radius:0rem ;
    -moz-border-radius:0rem ;
    -ms-border-radius:0rem ;
    -o-border-radius:0rem ;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins';
    padding: .5rem 1rem;
}
.tab-list li {
    line-height: 2.9 !important;
}
.tab-list li a:hover{
    cursor: pointer;
    
}
 .result-check .fill-color-outline{
    background: transparent !important;
    border: 1px solid #49A35A;
    color:#000;
}
.resultbg{
    width: 100%;
    height: 3vh;
    padding: 40px 0px !important;
    background: url(/images/herobg.svg);
    background-repeat: repeat;  
}
.result-check .list-inline-item {
    width: 30px;
    height: 30px;
    background: #49A35A;
    text-align: center;
    align-items: center;
    border-radius: 50%;
    padding: auto;
    color: #000;
    font-weight: 600;
    padding-top: 4px;
    font-size: 14px;
}


input[type='checkbox']{
    width: 15px !important;
    height: 15px !important;
   
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;

    box-shadow: none;
    font-size: 0.8em;
    text-align: center;
    line-height: 1em;
    background: #ba0b0b;
    outline:2px solid #ba0b0b ;
    outline-offset: -2px;
  }
  
  input[type='checkbox']:checked:after {
    content: '✔';
    color: #fff;
    line-height: 1em;
    width: 15px !important;
    height: 15px !important;
   
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;

    box-shadow: none;
    font-size: 0.8em;
    text-align: center;
   
   
  }

  .outline{
    border: 2px solid #ba0b0b;
    text-align: center;
  }


  /*  RADIO BUTTON */


  *,
*:before,
*:after {
  box-sizing: border-box;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]+label:before {
  content: "";
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 0px;
  margin-right: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #ba0b0b;
  background-color: transparent;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked + label:before {
  background-color: #ba0b0b;
}

/* optional styles, I'm using this for centering radiobuttons */
label {
  display: flex;
  align-items: center;
}
@media (max-width : 1024px){
 
.jackpot {
    width: 100%;
    height: 40vh;
    background: url(/images/herobg.svg);
    background-repeat: repeat;
    padding-top: 10rem;
}
}

@media (max-width : 768px){
 
    .jackpot {
        width: 100%;
        height: 50vh;
        background: url(/images/herobg.svg);
        background-repeat: repeat;
        padding-top: 10rem;
    }
    }
  
    @media (max-width : 540px){
 
        .jackpot {
            width: 100%;
            height: 70vh;
            background: url(/images/herobg.svg);
            background-repeat: repeat;
            padding-top: 10rem;
        }
        }
.howtoplay{
    border: 1px solid #b3b0b0;
    border-radius: 30px;
    margin-top: 2rem;
}
.howtoplay .thead {
    border-bottom: 1px solid #b3b0b0 !important;
}
.howtoplay tbody tr{
    border-top: 1px solid #b3b0b0;
}
.howtoplay tbody th{
    border-top: 1px solid #b3b0b0;
}

.howtoplay .list-inline-item.fill-color {
    width: 25px;
    height: 25px;
    background: linear-gradient(90.3deg, #C70C0C -0.61%, #5F0404 100.37%);
    text-align: center;
    align-items: center;
    border-radius: 50%;
    padding: auto;
    color: #fff;
    font-weight: 600;
    padding-top: 4px;
    font-size: 12px;
}
.howtoplay .list-inline-item.fill-color-outline{
    background: transparent;
    border: 1px solid #000;
}

.howtoplay .list-inline-item.no-fill-color{
    width: 25px;
    height: 25px;
    background: transparent;
    text-align: center;
   
    color: #000;
    font-weight: 400;
    font-size: 1.5rem;
    position: relative;
    top: -3px;
}


@media (max-width: 768px){
 .howtoplay .list-inline{
  
        margin-bottom: 0px !important;
        display: inline-flex; 
 }
 .howtoplay .list-inline-item.no-fill-color{
    top: -6px;
}
}
