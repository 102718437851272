.checkout-wrapper{
margin-top: 2rem;
}
.checkout-btn{
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1.3rem;
    padding: 1rem;
    color: #fff !important;
    margin-bottom: 1.5rem;
    border-radius: 0;
}

.checkout-btn-danger{
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1.3rem;
    padding: 1rem;
    color: #fff !important;
    background: rgb(199, 12, 12) ;
    border-radius: 0;
}
.checkout-card{
        box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.09);
        border-radius: .4rem;
}
.checkout-card-header{
    background: #d3d3d3;
    padding: 1.25rem .5rem;
   
}
.checkout-header h6{
    position: absolute;
    left: 8rem;
    margin-top: -7px;
}
.checkout-header img{
    position: absolute;
    top: -28px;
    
    left: 1px;
}
.border-bottom{
   border-bottom: 2px solid #d3d3d39c !important;
   padding-bottom: .5rem;
}
.view-more{
    padding-top: .2rem;
}
.view-more:hover{
    cursor: pointer;
   
}
.list-inline{
    margin-bottom: 0px !important;
}
/* .list-inline-item {
  
    width: 30px;
    height: 30px;
    background:#49A35A;
    text-align: center;
    align-items: center;
    border-radius: 50%;
    padding: auto;
  
    color: #fff;
    font-weight: 600;
    padding-top: 4px;
} */
.list-inline-item {
    width: 25px;
    height: 25px;
    background: #49A35A;
    text-align: center;
    align-items: center;
    border-radius: 50%;
    padding: auto;
    color: #fff;
    font-weight: 600;
    padding-top: 4px !important;
    font-size: 12px;
}
.list-inline-item:last-child{
 background: rgb(199, 12, 12) ;
}
.table-checkout {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border: 2px solid #d3d3d361 !important;
}
.table-checkout thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    color: #d3d3d3;
}

.result .table{
    background: #d2e8d661;
}

.result-thread{
    border-bottom: 1px solid #49A35A !important;
}

.make-me-sticky{
    position: -webkit-sticky;
      position: sticky;
      top: 15%;
      
      
}