*{
 font-family:poppins;
/* 
 Century Gothic,Arial,sans-serif */
}
.dashboard-wrapper{
    padding-top: 2rem;
}
#v-pills-tab{
box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.09);
/* border-right: 2px solid #C70C0C; */

}

.nav-pills .nav-link{
  padding: 1rem;
  border: 1px solid #7070701c;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.nav-pills .nav-link.active{
    color: #fff !important;
    background-color: #C70C0C !important;

}
.dashboard a{
    color: #222;
    font-size: 16px;
    font-weight: 600;
}
.dashboard-card {
    padding: 2rem 0rem 2rem 2rem;
    box-shadow:0px 7px 20px rgba(0, 0, 0, 0.09);
    border-radius: .4rem;
    
}

.dashboard-label label{
    font-size: 12px
}
.dashboard-header{
    padding-left: 5rem;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: 1.2rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
  
    background-clip: padding-box;
    border: 1px solid #f3f3f3;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ecedef;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgb(212 213 214 / 25%);
}

.form-group{
    padding:3px;
    border:2px solid #d3d3d37a;
    margin:10px;
    margin-left: 0px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
  .form-group>label{
    position:absolute;
    top:-1px;
    left:40px;
    padding: 2px 6px;

    background-color:white;
  }
  
  .form-group>input{
    border:none;
  }
  .dashboard-card a{
   color: #28a745!important;
  }
  .side-tab a{
    
        color: #222;
        font-size: 14px;
        /* font-weight: 600; */

  }

  @media (max-width: 540px) {
   .dashboard-header{
     padding-left: 1rem;
   }
  }





