.nav-pills .nav-link {
    padding: 1rem 2rem;
    border: 1px solid #7070701c;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    color: #000;
    font-weight: 600;
}
#v-pills-tab {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.00); 
    /* border-right: 2px solid #C70C0C; */
}

.nav-tab .nav-link {
    padding: .1rem  !important;
    border: 1px solid #70707000 !important;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    color: #000;
    margin: 0rem .7rem;
    font-weight: 700;
    font-size: 14px !important;
    
}
.nav-tab .nav-link.active {
    color: #C70C0C !important;
    background-color: transparent !important;
    border-bottom: 2px solid #C70C0C !important;
}

.img-tab img {
    width: 180px;
    height: 160px;
    object-fit: cover;
}
.check-result{
    font-size: 14px;
    font-family: poppins;
    font-weight: 600;
}
.img-wrappers {
 
    padding: .8rem;
    box-sizing: border-box;
    text-align: -webkit-center;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    cursor: pointer;
}

.menu {
    background: #ffffff;
    border-radius: 2px;
    border-top: 2px solid #C70C0C;
    position: absolute;
    top: 0;
    right: 0;
    width: 900px !important;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    z-index: 1;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
}

.menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }